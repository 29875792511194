body {
  background-color: #f9f9f9;
  color: #303030;
}

.title-container {
  text-align: center;
}

.roggue-header {
  font-family: "Forum", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 8em;
  margin-bottom: 0;
}

.roggue-letter {
  color: #777777;
  font-family: "Glass Antiqua", cursive;
  font-weight: 400;
  font-style: normal;
}

.roggue-subheader {
  font-family: "Cutive Mono", monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
  margin-top: 1em;
}

.feature-container {
  text-align: center;
  font-family: "Cutive Mono", monospace;
  font-weight: 400;
  font-style: normal;
  margin-top: 25vh;
}

#footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  text-align: center;
  font-family: "Cutive Mono", monospace;
  font-weight: 600;
  font-style: normal;
  font-size: 0.9em;
}
